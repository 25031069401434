import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { onUnmounted } from 'vue';

import { useNotification } from '@/modules/core';

const onDoneSubscribedCallbacks = [];

export const useDeleteInquiry = () => {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(REQUEST_INQUIRY_DELETE, {
    update: (cache, _, { variables: { requestId } }) => {
      cache.modify({
        id: cache.identify({
          id: requestId,
          __typename: 'OperationRequest',
        }),
        fields: {
          inquiries: (_, { DELETE }) => DELETE,
        },
      });
    },
  });

  onDone((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  const onDoneCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) => onDoneSubscribedCallbacks.splice(onDoneSubscribedCallbacks.indexOf(cb), 1));
  });

  onError((err) => {
    console.error('useDeleteInquiry', err);
    error();
  });

  return {
    mutate,
    loading,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDoneSubscribedCallbacks.push(callback);
    },
  };
};

const REQUEST_INQUIRY_DELETE = gql`
  mutation requestInquiryDelete($requestId: ID!, $inquiryId: ID!) {
    requestInquiryDelete(requestId: $requestId, inquiryId: $inquiryId)
  }
`;
