import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export const useCreateInquiry = () => {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(REQUEST_INQUIRY_CREATE, {
    update: (cache, { data: { requestInquiryCreate } }, { variables: { requestId } }) => {
      cache.modify({
        id: cache.identify({
          id: requestId,
          __typename: 'OperationRequest',
        }),
        fields: {
          inquiries: (inquiries) => (!inquiries ? [requestInquiryCreate] : [...inquiries, requestInquiryCreate]),
        },
      });
    },
  });

  onError((err) => {
    console.error('useCreateInquiry', err);
    error();
  });

  return {
    mutate,
    loading,
    onDone,
  };
};

const REQUEST_INQUIRY_CREATE = gql`
  mutation requestInquiryCreate($requestId: ID!, $data: RequestInquiryCreate) {
    requestInquiryCreate(requestId: $requestId, data: $data) {
      id
      timestamp
      content
    }
  }
`;
